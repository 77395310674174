import React, { useEffect, useState } from 'react'
import AdminNavbar from '../components/AdminNavbar'
import styles from "../styles/Dashboard.module.css";
import { Box, Grid, GridItem, Heading, Table, TableCaption, Tbody, Td, Th, Thead, Tr, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Button, ButtonSpinner } from '@chakra-ui/react'
import axios from 'axios';
import { Url } from '../constants/Blog';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';

const BookedUser = () => {
  const [userDetails, setUserDetails] = useState();
  const [singleDetails, setSingleDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [newStatus, setNewStatus] = useState('')
  const [rLoading, setRLoading] = useState(false)

  const callApi=()=>{
    setLoading(true)
    axios.get(`${Url}/api/v1/allBookings/spa`)
    .then(response => {
      setUserDetails(response.data.data);
      setLoading(false)
      console.log(response.data.data);
    })
    .catch(error => {
      setLoading(false)
      console.error(error);
    });
  }

 

    useEffect(()=>{
      callApi()
    },[])

  const fullDetails = (ud) => {
        setSingleDetails(ud);
        console.log(ud,'uuuus')
        setIsOpen(true);
      
  }

  const closeModal = () => {
    setIsOpen(false);
  };


    const refreshFun = () =>{
    setRLoading(true)
    axios
        .get(
          `https://networkpaymentgateway-production.up.railway.app/api/v1/subscription/${singleDetails.orderId}`
        )
        .then(function (response) {
          alert("refreshed Successfully")
          setRLoading(false)
        })
        .catch(function (error) {
          // handle error
          console.log(error, "err");
          alert("Error: Something went wrong")
          setRLoading(false)
        })
  }
  return (
    <>
      <AdminNavbar>
        <Box as="section">
          <Grid
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(3, 1fr)"
            className={styles.details}
            gap={4}
          >
            <GridItem
              colSpan={{ base: 4, xl: 4 }}
              className={styles.recentOrders}
            >
              <Box className={styles.cardHeader}>
                <Heading>Booked User List</Heading>
              </Box>
              <Table variant="striped" colorScheme="gray">
                <TableCaption>Booked User List</TableCaption>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Email</Th>
                    <Th>Date</Th>
                    <Th>Payment</Th>
                    <Th>Subtotal</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  
                  {loading ? <LoadingBox></LoadingBox>:
                  error? <MessageBox>{error}</MessageBox>:
                  userDetails && userDetails.length> 0 ? userDetails.slice().reverse().map((ud)=>(

                    <Tr key={ud.id} onClick={() => { fullDetails(ud) }} style={{cursor:"pointer"}}>
                      <Td>{ud.userInformation.bookerDetails.fullName}</Td>
                      <Td>{ud.userInformation.bookerDetails.email}</Td>
                      <Td style={{maxWidth:"200px"}}>{new Date(ud.userInformation.userDetails.arrivalDate).toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })} (Dubai local time)</Td>
                      {/* <Td>{ud.userInformation.guestDetails.length}</Td> */}
                       <Td>
                            {ud.payment_status
                              ? ud.payment_status
                              : ud.isDirectBooking
                              ? "Direct Booking"
                              : "Credit"}
                          </Td>
                      <Td>{ud.userInformation.totalPrice?.toFixed(2)} AED</Td>
                      {/* <Td>{ud.userInformation.loginInfo?.userType}</Td> */}
                      </Tr>
                  )):(
                    <MessageBox>No details</MessageBox>
                  )}
                </Tbody>
              </Table>
            </GridItem>
          </Grid>
        </Box>
      </AdminNavbar>

      <Modal isOpen={isOpen} onClose={closeModal}  size="6xl">
        <ModalOverlay />
        <ModalContent>

          <ModalHeader>Single User Details</ModalHeader>
          <ModalCloseButton />
          {/* <button onClick={refreshPayment}>Refresh</button> */}

          <ModalBody>
            {singleDetails && (
              <>
                <Box mb={4}>
                  <Heading as="h4" size="md" mb={2}>Customer Details</Heading>
                  <Table variant="simple">
                    <Tbody>
                      <Tr>
                        <Td>Order Number:</Td>
                        <Td>{singleDetails.orderNumber ? singleDetails.orderNumber : "N/A"}</Td>
                      </Tr>
                      <Tr>
                        <Td>First Name:</Td>
                        <Td>{singleDetails.userInformation.bookerDetails.fullName}</Td>
                      </Tr>
                      <Tr>
                        <Td>Email:</Td>
                        <Td>{singleDetails.userInformation.bookerDetails.email}</Td>
                      </Tr>
                      <Tr>
                        <Td>Mobile:</Td>
                        <Td>{singleDetails.userInformation.bookerDetails.mobile}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
                {singleDetails.payment_status && (
                  <Box mb={4}>
                    <Heading as="h4" size="md" mb={2}>Payment Details</Heading>
                    <Table variant="simple">
                      <Tbody>
                        <Tr>
                          <Td>Amount and Currency</Td>
                          <Td>{singleDetails.amount.value && singleDetails.amount.value + " " + singleDetails.amount.currencyCode && singleDetails.amount.currencyCode} </Td>
                        </Tr>
                        <Tr>
                          <Td>Order Id</Td>
                          <Td>{singleDetails.orderId && singleDetails.orderId} 
                            <Button style={{background:"#212a69" , padding:"10px", borderRadius:"10px",color:"#fff",marginLeft:"20px"}} onClick={()=>{refreshFun()}} disabled={rLoading} >Send Mail {rLoading && (<ButtonSpinner></ButtonSpinner>)}</Button>
                            </Td>
                        </Tr>
                        <Tr>
                          <Td>Payment Status</Td>
                          <Td>{singleDetails.payment_status && singleDetails.payment_status}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                )}
                
                <Box mb={4}>
                  <Heading as="h4" size="md" mb={2}>Transfer Information</Heading>
                  <Table variant="simple">
                    <Tbody>
                      {singleDetails.userInformation.userDetails.arrivalDate && (
                      <Tr>
                        <Td>Arrival Date & Time:</Td>
                        <Td>{new Date(singleDetails.userInformation.userDetails.arrivalDate).toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })} (Dubai local time)</Td>
                      </Tr>
                    )}
                      <Tr>
                        <Td>Arrival Flight Number:</Td>
                        <Td>{singleDetails.userInformation.userDetails.arrivalFlightNo}</Td>
                      </Tr>
                    {singleDetails.userInformation.userDetails.destinationDate && (
                      <Tr>
                        <Td>Departure Date & Time:</Td>
                        <Td>{new Date(singleDetails.userInformation.userDetails.destinationDate).toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })} (Dubai local time)</Td>
                      </Tr>
                    )}
                      <Tr>
                        <Td>Departure Flight Number:</Td>
                        <Td>{singleDetails.userInformation.userDetails.departureFlightNo}</Td>
                      </Tr>
                    <Tr>
                    <Td>Total Guests:</Td>
                    <Td>{singleDetails.userInformation.userDetails.guestNumber}</Td>
                    </Tr>
                </Tbody>
                </Table>

                </Box>
                <Box mb={4}>
                  <Heading as="h4" size="md" mb={2}>Guests</Heading>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Age</Th>
                        <Th>Gender</Th>
                        <Th>preferred Date & Time</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {singleDetails.userInformation.guestDetails.map((guest, index) => (
                        <Tr key={index}>
                          <Td>{guest.name}</Td>
                          <Td>{guest.age}</Td>
                          <Td>{guest.gender}</Td>
                          <Td>{guest.preferredDate}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
                <Box mb={4}>
                  <Heading as="h4" size="md" mb={2}>booker Information</Heading>
                  <Table variant="simple">
                    
                    <Tbody>
                          <Tr>
                            <Td>FirstName</Td>
                            <Td>{singleDetails.userInformation.bookerDetails.fullName}</Td>
                          </Tr>
                          <Tr>
                            <Td>Email</Td>
                            <Td>{singleDetails.userInformation.bookerDetails.email}</Td>
                          </Tr>
                          <Tr>
                            <Td>Mobile</Td>
                            <Td>{singleDetails.userInformation.bookerDetails.mobile}</Td>
                          </Tr>
                          <Tr>
                            <Td>Terminal</Td>
                            <Td>{singleDetails.userInformation.bookerDetails.terminal}</Td>
                          </Tr>
                          <Tr>
                            <Td>Special Instruction</Td>
                            <Td>{singleDetails?.userInformation?.bookerDetails?.note?.map((nt)=>(
                              <>{nt + ","}</>
                            ))}</Td>
                          </Tr>
                          
                          <Tr>
                            <Td>Remarks</Td>
                            <Td>{singleDetails.userInformation.bookerDetails.remarks}</Td>
                          </Tr>
                          
                    </Tbody>
                  </Table>
                </Box>
                <Box>
                  <Heading as="h4" size="md" mb={2}>Selected Packages</Heading>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Title</Th>
                        <Th>Category Title</Th>
                        <Th>Duration (in minutes)</Th>
                        <Th>Price</Th>
                       

                      </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                          <Td>{singleDetails.userInformation.selectedService.serviceTitle}</Td>

                          <Td>{singleDetails.userInformation.selectedCategory.categoryTitle}</Td>
                          <Td>{singleDetails.userInformation.selectedPackage.duration}</Td>
                          <Td>{singleDetails.userInformation.selectedPackage.price} AED</Td>
                        

                        </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default BookedUser;
